<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">Request Data Export</v-card-title>

      <div v-if="showRequestedPrompt">
        <v-card-text>   
          <v-alert type="success">
            <p>Your request has been received. <strong>It might take a few hours to prepare your data export.</strong></p>
            
            <p>Once completed, we will send you an email to {{ profile.email }} with a link where you can download your data export.</p>
          </v-alert>

        </v-card-text>
      </div>
      <div v-else-if="profile">
        <v-card-text>   
          <p>
            On your request, we can send you an export of all data we store on you (data takeout). This includes your profile and all other personal information we store for you. This includes your activities and the devices you have connected.
          </p>
          <p>
            After submitting the request we will start the export and send you an email within a few hours with a link to download your data.
          </p>
          <p>
            The data is provided as-is and is a raw dump of the data we have on you for your reference. The data is in a technical file format and might not be easily readable. You can request a new data export at most once per week (7 days).
          </p>

          <v-text-field
            v-model="profile.email"
            readonly
            disabled
            label="Your email"
            prepend-icon="fa fa-envelope"
            />
          <v-alert v-if="!profile.email" type="warning">Email address is empty. Please edit your profile and set your email before you request an export.</v-alert>
          <v-alert v-if="profile.email" type="info">Please double check this is the correct email address. Upon your request we will send the data export to this email so anyone with access to this email address will be able to see your data.</v-alert>
        </v-card-text>
        
        <v-card-text>   
          <v-btn block :disabled="!isValid" color="primary" :loading="$store.getters.isLoading" @click="submit">Request Data Export</v-btn>
        </v-card-text>   

      </div>      

    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Request Data Export', disabled: true },
      ],
      profile: null,
      showRequestedPrompt: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async submit() {
      let response = await profileService.requestExport();
      if (response.data.status == 'OK') {
        this.showRequestedPrompt = true;
      }
      else {
        this.$helpers.toastResponse(this, response.data);
      }
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
      }
    },
  },
  computed: {
    isValid() {
      return this.profile && this.profile.email;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

